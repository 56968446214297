<template>
  <div class="p-grid" style="margin-right:-37px;">
    <Breadcrumb class="p-col-11" :home="breadcrumb.home" :model="breadcrumb.items"
                style="padding-top:0px;background: none;border: none;"/>
    <Button @click="back()" label="返回" class="p-col-1 p-button-sm p-button-outlined p-button-secondary"
            style="padding:0px;width:65px;border:1px solid rgba(217, 217, 217, 1);margin-bottom:10px;"/>
  </div>
  <div class="card">
      <div class="p-grid">
        <div class="p-col-3">
          <label>指标类型：</label>
          <Dropdown @change="condition.channel='ALL'" @keypress="$event.keyCode==13?redraw():{}" style="width:60%" :filter="true" placeholder="请选择"
                    :options="dataTypeList" v-model="condition.dataType" optionLabel="cname" option-value="ename"/>
        </div>
        <div class="p-col-3">
          <label>展示节点：</label>
          <Dropdown @change="condition.channel=(condition.showType=='节点展示'?'ALL':'总和')" @keypress="$event.keyCode==13?redraw():{}" style="width:60%" :filter="true" placeholder="请选择"
                    :options="showTypeList" v-model="condition.showType" optionLabel="name" option-value="code"/>
        </div>
        <div class="p-col-4">
          <label>时间段：</label>
          <Calendar @keypress="$event.keyCode==13?redraw():{}" style="width: 70%" placeholder="请选择" v-model="condition.timeRange" :manualInput="false" selectionMode="range"
                    dateFormat="yy/mm/dd" :numberOfMonths="2"
                    :show-icon="true"/>
        </div>
        <div class="p-col-2">
          <Button style="height:33px;border-color: #d9d9d9" @click="reset()" label="重置" class="p-button-outlined p-button-secondary p-mr-2"/>
          <Button style="background: #12A25D;height:33px;" label="查询" class="p-mr-2" @click="redraw()"/>
        </div>
      </div>
    <div v-if="chartData[0]" style="padding:10px 0px 10px 0px;">
      采集通道：
      <label v-if="condition.showType!='总和展示'" @click="channelChanged('ALL')" :class="{'channel-selected':condition.channel=='ALL','channel-unselected':condition.channel!='ALL'}" style="margin:10px;cursor:pointer;">全部</label>
      <label @click="channelChanged(item)" v-bind:key="item" v-for="item of chartData[0].channel" :class="{'channel-selected':condition.channel==item,'channel-unselected':condition.channel!=item}" style="margin:10px;cursor:pointer;">
        {{item}}
      </label>
    </div>
  </div>

  <div :style="{'display':isLoading?'block':'none'}" id="main_loading" style="width:100%;height:600px;"></div>
  <div v-if="!isLoading" style="display:flex;justify-content: space-between;flex-wrap: wrap">
    <div v-bind:key="item" v-for="item of chartData" class="card"  style="width:calc(50% - 8px);height:400px;margin-bottom:40px;">
      <div style="margin-left:6px;font-size:18px;font-weight:bold;">
        <div style="float:left;width:auto;margin-top:3px;z-index:1000;">{{ (item.detail?item.detail.dname:'/')+ "（编号：" + item.fullid + "）"}}</div>
        <Button @click="exportCSV(item.fullid)" label="下载" style="float:right;width:61px;height:32px;border: 1px solid #D9D9D9;color:rgba(0, 0, 0, 0.65);font-size:14px;z-index:1000;" class="p-button-outlined p-button-secondary" />
      </div>
      <div :id="'main_'+item.fullid" style="width:100%;height:400px;"></div>
    </div>
  </div>

  <!-- 底部空白占位符 -->
  <div style="height:300px;"></div>

  <Toast/>

</template>

<script>
export default {
  components: {},
  data() {
    return {
      echarts: window["echarts"],
      breadcrumb: {
        home: {label: '种植项目', to: '#'},
        items: [
          {label: '数据分析', to: '/data/compare'},
          {label: '分图表对比', to: '#'}
        ],
      },
      showTypeList : [
        {name: '节点展示', code: '节点展示'},
        {name: '总和展示', code: '总和展示'}
      ],
      dataTypeList: [],
      chartData: [],//不能是null
      condition: {
        dataType: null,
        showType: null,
        timeRange: [], // 不能为空，默认要使用
        channel: "ALL" // 默认显示全部管道数据
      },
      currChartOption: {},
      isLoading: true
    }
  },
  mounted() {

    // 显示页面预加载动画
    this.showLoading();

    this.getTypeList();

    // 根据url参数设置时间段
    if(this.$route.query.startDate && this.$route.query.endDate){
      this.condition.timeRange = [new Date(this.$route.query.startDate),new Date(this.$route.query.endDate)]
    }else{
      // 如果父页面没有选择时间，则默认为前一周
      this.condition.timeRange = [new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),new Date()]
    }
  },
  methods: {
    showLoading(){
      // 显示页面预加载动画
      this.isLoading = true;
      let myChart = this.echarts.init(document.getElementById('main_loading'));
      myChart.showLoading({
        text: '正在加载图表...',
        color: 'rgba(18, 162, 93, 1)',
        textColor: 'rgba(18, 162, 93, 1)',
        maskColor: 'rgba(18, 162, 93, 0.3)',
      });
    },
    hideLoading(){
      this.isLoading = false;
    },
    exportCSV(fullId){

      // 查找指标类型的cname
      let cname = "";
      for(let i in this.dataTypeList){
        let option = this.dataTypeList[i];
        if(option.ename == this.condition.dataType){
          cname = option.cname;
        }
      }

      let ownOption = this.currChartOption[fullId];

      let content ="时间,参数,";
      for(let i in ownOption.legend_4_csv.data){
        let curr = ownOption.legend_4_csv.data[i];
        content += curr + ",";
      }
      content +="\n";

      for(let i in ownOption.xAxis.data){
        content += ownOption.xAxis.data[i] + "," // 时间
        content += cname + ","; // 指标名称
        for(let j in ownOption.series){
          let ser = ownOption.series[j];
          if(ser.data.length > 0){
            let value = ser.data[i];
            content += (value?value:"") + ","
          }
        }
        content += "\n";
      }

      // 创建Blob对象 传入一个合适的MIME类型
      const blob = new Blob(['\ufeff' + content], {type: 'text/csv,charset=UTF-8'});
      // 使用 Blob 创建一个指向类型化数组的URL
      const csvUrl = URL.createObjectURL(blob);
      let link = document.createElement('a');
      link.download = fullId + "_"+ cname + "_" + new Date().getTime() + ".csv"; //文件名字
      link.href = csvUrl;
      link.click();
    },
    channelChanged(channel){
      this.condition.channel = channel;
      this.redraw();
    },
    back(){
      this.$router.push("/data/compare");
    },
    redraw(){

      this.showLoading();

      if(!this.condition.dataType || !this.condition.showType || !this.condition.timeRange){
        this.$toast.add({severity: 'warn', summary: '输入错误！', detail: '请将条件输入完整！', life: 3000});
        return;
      }

      if(this.condition.showType == "节点展示" && this.condition.channel == "总和"){
        this.condition.channel = "ALL";
      }

      // 拼接设备id
      let devids = "";
      let devsObj = JSON.parse(this.$route.query.devs)
      for(let i in devsObj){
        devids += devsObj[i] + ","
      }
      devids = devids.substring(0, devids.length - 1)

      this.query(
          devids,
          this.condition.dataType,
          this.condition.channel,
          this.condition.showType,
          new Date(this.condition.timeRange[0]).toLocaleDateString(),
          new Date(this.condition.timeRange[1]).toLocaleDateString()
      )
    },
    query(devIds, kpiEName, channel, showType, start, end){
      let endTime = new Date(
        new Date(end).getTime() + 1 * 24 * 60 * 60 * 1000
      ).toLocaleDateString();
      this.$http("/kpi/getSplitCompareEChartsData", "get", {
        devIds: devIds,
        kpiEName: kpiEName,
        channel: channel,
        showType: showType,
        start: start,
        end: endTime
      }, (resp)=>{
        this.chartData = resp.data;
        this.hideLoading();
        for(let i in resp.data){
          let item = resp.data[i];
          // 等待dom渲染完成
          setTimeout(()=>{
            if(item.time.length != 0 && item.channel.length != 0){
              this.drawChart(item)
            }else{
              document.getElementById('main_' + item.fullid).innerHTML = "<div style='text-align:center;padding-top:30%;'>无数据</div>";
            }
          },200)
        }
      });
    },
    drawChart(item) {

      // 默认规则
      // 处理timelist，如果默认时分秒都是00:00:00，则省略所有timelist的时分秒
      let isAllTimeZero = true;
      for(let i in item.time){
        let time = item.time[i];
        if(!time.endsWith("00:00:00")){
          isAllTimeZero = false;
        }
      }
      if(isAllTimeZero) {
        for (let i in item.time) {
          item.time[i] = item.time[i].replace("00:00:00", "").trim()
        }
      }

      // 找到记录的最后七天的起始时间点
      let endTime = new Date(item.time[item.time.length - 1].replace(/ /g,"T")).getTime();
      let startTime = endTime - 7*24*60*60*1000;
      for(let i in item.time){
        let time = item.time[i];
        let dateObj = new Date(time);
        if(typeof startTime == 'number' && dateObj.getTime() >= startTime){
          startTime = time;
        }
        if(typeof endTime == 'number' && dateObj.getTime() >= endTime){
          endTime = time;
        }
      }

      // 基于准备好的dom，初始化echarts实例
      var myChart = this.echarts.init(document.getElementById('main_' + item.fullid));

        let option = {
          title: {
            // text: item.detail.dname + "（编号：" + item.fullid + "）"
            // subtext: '纯属虚构'
          },
          tooltip: {
            trigger: 'axis'
          },
          legend_4_csv: {
            data: []
          },
          dataZoom: [
            {
              type: 'slider',
              startValue: startTime,
              endValue:  endTime
            }
          ],
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: item.time
          },
          yAxis: {
            type: 'value',
            // axisLabel: {
            //   formatter: '{value} °C'
            // }
          },
          series: []
        };

      for (let i in item.channel) {
        let c = item.channel[i]

        if (c) {
          let dataList = item[c];
          if(dataList && dataList.length > 0) {
            option.legend_4_csv.data.push(c)
            option["series"].push({
              name: c,
              type: 'line',
              data: dataList
            })
          }
        }
      }

      if(this.condition.showType != "总和展示"){
        option.tooltip["formatter"] = function(params) {
          let max = -99999;
          let min = 99999;
          let avg = 0;
          let sum = 0;
          let html = params[0].axisValue+"<br/><br/>";
          for(let i in params){
            let record = params[i];
            html += record.seriesName + "：" + record.value + " " + item.valutUnit + "<br/>"
            sum += record.value;
            if(record.value > max){
              max = record.value;
            }
            if(record.value < min){
              min = record.value;
            }
          }
          avg =(sum / params.length).toFixed(2);
          max = max.toFixed(2);
          min = min.toFixed(2);

          html += "<hr/>最大值：" + max+ " " + item.valutUnit + "<br/>最小值：" + min+ " " + item.valutUnit + "<br/>平均值：" + avg+ " " + item.valutUnit;
          return html;
        }
      }

        this.currChartOption[item.fullid] = option;

        // 使用刚指定的配置项和数据显示图表
        myChart.setOption(option);
    },
    reset(){
      this.condition.dataType = null;
      this.condition.timeRange = null;
      this.condition.showType = null;
    },
    getTypeList(){
      this.$http("/kpiname/listByDevType", "post", {devs: this.$route.query.devs}, (resp)=>{
        this.dataTypeList = resp.data;

        if(this.dataTypeList.length > 0){
          // 默认查询第一个指标类型的数据
          this.condition.dataType = resp.data[0].ename;
          this.condition.showType = '节点展示';
          // 拼接设备id
          let devids = "";
          let devsObj = JSON.parse(this.$route.query.devs)
          for(let i in devsObj){
            devids += devsObj[i] + ","
          }
          devids = devids.substring(0, devids.length - 1)

          this.query(devids, resp.data[0].ename, "ALL", this.condition.showType, new Date(this.condition.timeRange[0]).toLocaleDateString(),new Date(this.condition.timeRange[1]).toLocaleDateString())
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.channel-selected{
  font-weight: bold;
  color: white;
  background-color: #12A25D;
  border-radius: 50px;
  padding:2px 4px 2px 4px;
}
.channel-unselected{
  font-weight: normal;
  color: black;
  background-color: white;
  border-radius: 50px;
  padding:2px 4px 2px 4px;
}
.a-button {
  color: #12A25D;
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
}

.admin-status {
  width: 56px;
  height: 22px;
  border: 1px double #91D5FF;
  color: #1890FF;
  font-size: 12px;
  padding: 4px 8px 4px 8px;
  background-color: #E6F7FF;
}

.user-status {
  width: 56px;
  height: 22px;
  border: 1px double #B7EB8F;
  color: #52C41A;
  font-size: 12px;
  padding: 4px 8px 4px 8px;
  background-color: #F6FFED;
}

.select1 {
  display: flex;
  justify-content: space-between;
}

//去掉表格底部的线条
::v-deep(.p-paginator-bottom) {
  border-bottom: none !important;
}
::v-deep(.p-checkbox .p-checkbox-box.p-highlight) {
  border-color: #12A25D;
  background: #12A25D;
}
::v-deep(.p-checkbox .p-checkbox-box.p-highlight:hover) {
  border-color: #12A25D;
  background: #12A25D;
}
::v-deep(.p-calendar-w-btn .p-button) {
  border-color: #12A25D;
  background: #12A25D;
}
::v-deep(.p-checkbox .p-checkbox-box:hover) {
   border-color: #12A25D;
 }

</style>
